import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-error-login',
  standalone: true,
  imports: [JsonPipe],
  templateUrl: './error-login.component.html',
  styleUrl: './error-login.component.scss',
})
export class ErrorLoginComponent {
  protected env = environment;
  protected url = this.env.redirectUrl;
  
  constructor() {
    localStorage.clear();
  }
}
