import { Component } from '@angular/core';
import { MatchaElevationModule } from 'matcha-components';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatchaElevationModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  switchTheme(className: string): void {
    document.body.classList.remove('theme-default-light');
    document.body.classList.remove('theme-default-dark');
    document.body.classList.remove('theme-dark');
    document.body.classList.remove('theme-kids');
    document.body.classList.add(className);
  }
}
