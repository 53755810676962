import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ErrorLoginComponent } from './pages/error-login/error-login.component';

export const routes: Routes = [
  {
    path: 'finish',
    loadComponent: () =>
      import('./pages/finish/finish.component').then(
        (r) => r.FinishComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'layout_definition',
    loadComponent: () =>
      import('./pages/layout-definition/layout-definition.component').then(
        (r) => r.LayoutDefinitionComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'user_permissions',
    loadComponent: () =>
      import('./pages/permissions/permissions.component').then(
        (r) => r.PermissionsComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'apple_account',
    loadComponent: () =>
      import('./pages/apple-account/apple-account.component').then(
        (r) => r.AppleAccountComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'financial_account',
    loadComponent: () =>
      import('./pages/financial-account/financial-account.component').then(
        (r) => r.FinancialAccountComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'church_website',
    loadComponent: () =>
      import('./pages/web-site/web-site.component').then(
        (r) => r.WebSiteComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'cnpj_registration',
    loadComponent: () =>
      import('./pages/cnpj-registration/cnpj-registration.component').then(
        (r) => r.CnpjRegistrationComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'basic_data',
    loadComponent: () =>
      import('./pages/basic-data/basic-data.component').then(
        (r) => r.BasicDataComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'password_setting',
    loadComponent: () =>
      import('./pages/define-password/define-password.component').then(
        (r) => r.DefinePasswordComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    loadComponent: () =>
      import('./pages/welcome/welcome.component').then(
        (r) => r.WelcomeComponent
      ),
    canActivate: [AuthGuard],
  },
  { path: 'onboarding', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'not-found', component: ErrorLoginComponent },
  { path: '**', component: ErrorLoginComponent },
];
