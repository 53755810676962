<app-header></app-header>
<div class="d-flex-column content mx-16 mx-md-60">
    @defer {
    <router-outlet></router-outlet>
    }

    @loading {
    <mat-spinner color="accent" diameter="50"></mat-spinner>
    }

</div>
<app-footer></app-footer>